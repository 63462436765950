import React, { useState } from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { PostCard } from "../components/PostCard"
import { CTABlock } from "../components/CTABlock"
import { FadeInWrapper } from "../components/FadeInWrapper"
import { FilterTags } from "../components/FilterTags"

const Blog = props => {
  const data = props.data.allMarkdownRemark.edges
  const blogCategories = props.data.site.siteMetadata.blogCategories
  const blogsDestructured = []

  data.map(blog => {
    return blogsDestructured.push({
      ...blog.node.frontmatter,
      ...blog.node.fields,
    })
  })

  const [blogs, setBlogsState] = useState(blogsDestructured)

  return (
    <Layout page="Blog">
      <SEO title="Blog" />
      <div className="bg-gray-100">
        <div className="container px-6 py-32 mx-auto sm:px-8 md:px-12 lg:px-6 md:py-40 lg:py-48 xl:py-56">
          <div className="xl:px-24">
            <h1>Explore the blog for expert advice on facial aesthetics.</h1>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="md:pt-0">
          <div className="bg-white">
            <FilterTags
              types={blogCategories}
              items={blogsDestructured}
              setItemsState={setBlogsState}
            />
          </div>
        </div>
      </div>
      <div className="grid px-6 py-6 bg-gray-100 sm:px-8 md:px-12 lg:px-20 lg:grid-cols-4">
        <div className="container col-span-12 px-6 py-16 mx-auto sm:px-8 md:px-12 lg:px-12">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            {blogs.map(
              (
                {
                  title,
                  category,
                  path,
                  date,
                  excerpt,
                  image,
                  imageLarge,
                  readingTime,
                },
                i
              ) => (
                <PostCard
                  key={`post-card-${i}`}
                  title={title}
                  category={category}
                  path={path}
                  date={date}
                  excerpt={excerpt}
                  image={image}
                  imageLarge={imageLarge}
                  readingTime={readingTime}
                />
              )
            )}
          </div>
        </div>
      </div>
      <FadeInWrapper>
        <CTABlock />
      </FadeInWrapper>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blogs/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            layout
            title
            id
            category
            author
            path
            date(formatString: "MMMM DD, YYYY")
            excerpt
            image
            imageLarge
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        blogCategories {
          title
          uid
        }
      }
    }
  }
`
